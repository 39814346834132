.chart-container {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin: auto;
  padding: 1rem;
}

.bar {
  fill: #7205f7;
  transition: height 0.5s;
}

.bar:hover {
  fill: #6e02f2;
}
/* 
.tooltip {
  position: absolute;
  background-color: white;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
  pointer-events: none;
  font-size: 14px;
} */

svg {
  font-family: Sans-Serif, Arial;
}
.line {
  stroke-width: 2;
  fill: none;
}

.axis path {
  stroke: black;
}

.text {
  font-size: 12px;
}

.title-text {
  font-size: 12px;
}

.chart-title {
  font-size: 18px;
  fill: #333;
}

.axis text {
  font-size: 12px;
}

.line-group {
  fill: none;
  stroke-width: 2px;
}
.names-container {
  background-color: white;
  width: 20%;
  padding: 0.5rem;
  overflow-y: auto;

  max-height: 480px;
}
.color-box {
  height: 8px;
  width: 8px;
}

svg {
  font-family: Arial, sans-serif;
}

.title-text {
  font-size: 10px;
  font-weight: 600;
}

.name-label {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  margin: 5px;
  font-size: 10px;
  word-break: break-all;
}

.name-label:hover {
  text-decoration: underline;
  font-weight: 600;
  scale: 1;
}

.name-hover-text {
  font-size: 10px;
  font-weight: 600;
}
