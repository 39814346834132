body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 0.5rem;
}

::-webkit-scrollbar-track {
  background: #e0e0e0;
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 0.5rem;
}
::-webkit-scrollbar-track :hover {
  background: #555;
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 0.5rem;
}
