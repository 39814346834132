body .MuiTypography-root {
  font-family: "Poppins", "Helvetica", sans-serif;
}

body .MuiModal-root .MuiDrawer-paperAnchorRight {
  padding-bottom: 70px;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
    0 16px 24px 2px rgba(0, 0, 0, 0.04), 0 6px 30px 5px rgba(0, 0, 0, 0.05);
  z-index: 2;
}
/* header.css-19z1ozs-MuiPaper-root-MuiAppBar-root,
header.css-1191obr-MuiPaper-root-MuiAppBar-root {
  background-color: transparent;
  box-shadow: none;
  z-index: 1200;
}
header.css-1191obr-MuiPaper-root-MuiAppBar-root {
  width: 100%;
  padding-left: 0;
}
.css-h4y409-MuiList-root {
  padding: 0 !important;
}
.css-bshv44-MuiButtonBase-root-MuiListItem-root {
  padding: 5px 17px !important;
}
.s-memu svg {
  width: 0.8em;
}
.MuiAutocomplete-popper {
  z-index: 1100 !important;
}
.MuiAutocomplete-popper ul li {
  padding: 0 8px;
}
.MuiAutocomplete-popper ul li .MuiCheckbox-root {
  padding: 0;
}
body .MuiDrawer-root {
  z-index: 1100;
}
body .MuiModal-root .MuiDrawer-paperAnchorRight {
  padding: 30px;
  padding-bottom: 70px;
  top: 48px;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
    0 16px 24px 2px rgba(0, 0, 0, 0.04), 0 6px 30px 5px rgba(0, 0, 0, 0.05);
  background-color: #fff; 
}
body .MuiModal-root .MuiDrawer-paperAnchorRight .drawerHead {
  margin-bottom: 16px;
}
@media (min-width: 600px) {
  .css-1u2mxbp {
    min-height: 48px !important;
  }
} */
text.content:contains("NullCount: 0") {
  color: red;
}
