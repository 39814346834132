/* ADLoginLoading.module.css */

.centerdiv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.centerdiv ul {
  display: flex;
  margin-left: 0px;
  padding-left: 0px;
}

.centerdiv ul li {
  list-style: none;
  height: 18px;
  width: 18px;
  background-color: red;
  margin: 0 6px;

  border-radius: 50%;
  animation: bounce 1s ease-in-out infinite;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }
}

.centerdiv ul li:nth-child(1) {
  animation-delay: 0.2s;
  background-color: #7c33f3;
}

.centerdiv ul li:nth-child(2) {
  animation-delay: 0.4s;
  background-color: #f700ba;
}

.centerdiv ul li:nth-child(3) {
  animation-delay: 0.1s;
  background-color: #ff287f;
}

.centerdiv ul li:nth-child(4) {
  animation-delay: 0.6s;
  background-color: #ff8152;
}

.centerdiv ul li:nth-child(5) {
  animation-delay: 0.3s;
  background-color: #ffc246;
}

.centerdiv ul li:nth-child(6) {
  animation-delay: 0.1s;
  background-color: #f9f871;
}

/* Add styles for the loading message */
.loading-message {
  margin-top: 1.2rem;
  font-size: 16px;
  color: #333; /* Adjust the color for better visibility */
}
